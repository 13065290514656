import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import TimerApp from "./TimerApp";

class App extends Component {
  render() {

    ReactGA.initialize('UA-128981067-1');
    ReactGA.pageview('/homepage');

    return (
        <TimerApp/>
    );
  }
}

export default App;
