
// Add zero padding
function zeroPad(n: number, minSize: number = 2): string {
    let s = String(n);
    while (s.length < minSize) { s = '0' + s;}
    return s;
}

// Convert time from miliseconds int to hh:mm:ss.S string
export default function timeFormat(milliseconds: number, showHours: boolean = false): string {

    let remaining: number = milliseconds / 1000;

    const hh: number = parseInt( "" + (remaining / 3600), 10 );

    remaining %= 3600;

    const mm = parseInt( "" + (remaining / 60), 10 );
    const ss = parseInt( "" + (remaining % 60), 10 );
    const S  = parseInt( "" + (milliseconds % 1000) / 100, 10 );

    const hoursString = showHours && `${ zeroPad(hh) }:` || "";
    return `${hoursString}${ zeroPad( mm ) }:${ zeroPad( ss ) }.${ S }`;
}