/* eslint-disable no-shadow */
// tslint:disable:no-console

import { Theme, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import timeFormat from "./StopWatchTimeDisplay";
import { Person } from "./TimerApp";
import { green, red } from "@material-ui/core/colors";

const styles = (theme: Theme) => ({
  submit: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  timer: {
    fontSize: 16
  },
  percentage: {
    fontSize: 20
  },
  good: {
    backgroundColor: green[200],
    fontSize: 20
  },
  bad: {
    backgroundColor: red[200],
    fontSize: 20
  },
  google: {
    width: "100%",
    marginTop: theme.spacing.unit * 4,
    background: theme.palette.common.white
  },
  googleButton: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: theme.spacing.unit,
      height: 18
    }
  }
});

interface Props extends WithStyles<typeof styles> {
  person: Person;
}

interface State {
  timeTalking: number;
  percentage: number;
}

class Timer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeTalking: this.props.person.timeTalking || 0,
      percentage: this.props.person.percentage || 0
    };
  }

  public componentWillReceiveProps(newProps: Props) {
    if (
      this.state.timeTalking !== newProps.person.timeTalking ||
      this.state.percentage !== newProps.person.percentage
    ) {
      this.setState({
        timeTalking: newProps.person.timeTalking,
        percentage: newProps.person.percentage
      });
    }
  }

  public render() {
    const { classes } = this.props;

    // const timeDisplay = (this.state.timeTalking / 1000).toFixed(2);
    const percentage = (this.state.percentage * 100).toFixed(0);

    return (
      <div className={classes.timer}>
        <div>{this.props.person.name}</div>
        <div>
          <span>{timeFormat(this.state.timeTalking)}</span>
          &nbsp;
          <span
            className={
              this.props.person.isTalkingEnough ? classes.good : classes.bad
            }
          >
            ({percentage}
            %)
          </span>
          &nbsp;
          <div>
            ({this.props.person.turnsTaken} turns)
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Timer);
