import { AppBar, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';

const PageTitle = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="title" color="inherit">
        Am I Talking Enough?
      </Typography>
    </Toolbar>
  </AppBar>
);

export default PageTitle;
