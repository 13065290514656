/* eslint-disable no-shadow */
// tslint:disable:no-console

import {
  Button,
  Grid,
  TextField,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import * as _ from "lodash";
import ReactGA from "react-ga";
import * as React from "react";
import timeFormat from "./StopWatchTimeDisplay";
import Timer from "./Timer";
import Page from "./components/Page";

const styles = (theme: Theme) => ({
  submit: {
    width: "100%",
    marginTop: theme.spacing.unit * 4
  },
  stopTheClock: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  button: {
    width: "50%",
    marginTop: theme.spacing.unit
  },
  startButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 4
  },
  activeTalker: {
    backgroundColor: lightBlue[200]
  },
  personName: {
    width: "50%"
  },
  google: {
    width: "100%",
    marginTop: theme.spacing.unit * 4,
    background: theme.palette.common.white
  },
  googleButton: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: theme.spacing.unit,
      height: 18
    }
  },
  config: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  proudly: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
    marginTop: 20
  }
});

interface Props extends WithStyles<typeof styles> {}

export interface Person {
  name: string;
  gender: "Male" | "Female";
  timeTalking: number;
  turnsTaken: number;
  percentage: number;
  isTalkingEnough?: boolean;
  varianceFromEqualTalking?: number;
}

interface State {
  show: string;
  people: Person[];
  newPerson: Person;
  totalTime: number;
  whoIsTalking?: Person;
}

function person(name: string, gender: "Male" | "Female") {
  return { name, gender, timeTalking: 0, turnsTaken: 0, percentage: 0, timer: {} };
}

class TimerApp extends React.Component<Props, State> {
  public timer: any = {};
  public tickIncrement: number = 100;

  constructor(props: Props) {
    super(props);

    const people: Person[] = [
      person("Adrian", "Male"),
      person("Virginia", "Female")
    ];
    const newPerson: Person = person("", "Female");

    this.state = {
      show: "help",
      people,
      newPerson,
      totalTime: 0,
      whoIsTalking: undefined
    };
  }

  public updateWhoIsTalking = (p: Person) => {
    ReactGA.event({
      category: "Timer",
      action: "Update talker"
    });

    if (!this.state.whoIsTalking) {
      this.startTimer();
    }
    if (!this.state.whoIsTalking || this.state.whoIsTalking.name !== p.name) {
      p.turnsTaken = p.turnsTaken + 1;
    }
    this.setState({ whoIsTalking: p });
  };

  public handleNewPersonNameChange = (e: any) => {
    const newPerson = person(e.target.value, this.state.newPerson.gender);
    this.setState({ newPerson });
  };

  public setGender = (gender: "Male" | "Female") => {
    const newPerson = person(this.state.newPerson.name, gender);
    this.setState({ newPerson });
  };

  public applyConfig = () => {
    ReactGA.event({
      category: "Timer",
      action: "Apply config"
    });
    this.setState({ show: "timer" });
  };

  public showConfig = () => {
    ReactGA.event({
      category: "Timer",
      action: "Show config"
    });
    this.setState({ show: "config" });
  };

  public showHelp = () => {
    ReactGA.event({
      category: "Timer",
      action: "Show help"
    });
    this.setState({ show: "help" });
  };

  public addPerson = (name: string, gender: "Male" | "Female") => {
    const newPeopleArray = [...this.state.people, person(name, gender)];
    this.setState({ people: newPeopleArray });
  };

  public deletePerson = (p: Person) => {
    const newPeopleArray = _.filter(
      this.state.people,
      (p1: Person) => p1.name !== p.name
    );
    this.setState({ people: newPeopleArray });
  };

  public savePerson = () => {
    this.addPerson(this.state.newPerson.name, "Male");
    this.setState({ newPerson: person("", "Female") });
  };

  public tick = () => {
    this.setState((prevState, props) => {
      const whoIsTalking: Person | undefined = prevState.whoIsTalking;
      const numPeople = prevState.people.length;
      const newTotalTime = prevState.totalTime + this.tickIncrement;
      if (whoIsTalking) {
        const newPeople = [...this.state.people];
        _.each(newPeople, (p: Person) => {
          if (p.name === whoIsTalking.name) {
            p.timeTalking = p.timeTalking + this.tickIncrement;
          }
          p.percentage = p.timeTalking / newTotalTime;
          p.varianceFromEqualTalking =
            Math.round((1 / numPeople - p.percentage) * 100) / 100;
          p.isTalkingEnough =
            Math.abs(p.varianceFromEqualTalking) <= 0.05 ? true : false;
        });
        return { totalTime: newTotalTime, people: newPeople };
      }
      // no one is talking yet
      return { totalTime: newTotalTime, people: prevState.people };
    });
  };

  public startTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.tick, this.tickIncrement);
  };

  public stopTimer = () => {
    ReactGA.event({
      category: "Timer",
      action: "Stop the clock"
    });

    clearInterval(this.timer);
    this.setState({ whoIsTalking: undefined });
  };

  public resetTimer = () => {
    ReactGA.event({
      category: "Timer",
      action: "Reset the clock"
    });

    clearInterval(this.timer);
    const newPeople = [...this.state.people];
    _.each(newPeople, (p: Person) => {
      p.timeTalking = 0;
      p.percentage = 0;
      p.turnsTaken = 0;
    });
    this.setState({ totalTime: 0, people: newPeople, whoIsTalking: undefined });
  };

  public render() {
    const { classes } = this.props;

    const totalTime = timeFormat(this.state.totalTime);

    const genderSelector = (
      <div>
        <Button onClick={() => this.setGender("Female")}>
          {this.state.newPerson.gender === "Female" && <span>*</span>}
          Female
        </Button>
        <Button onClick={() => this.setGender("Male")}>
          {this.state.newPerson.gender === "Male" && <span>*</span>}
          Male
        </Button>
      </div>
    );

    const showGenderSelector = false;

    const whoIsTalking = this.state.whoIsTalking
      ? this.state.whoIsTalking.name
      : "no one";

    // console.info(whoIsTalking);

    return (
      <Page>
        {this.state.show === "help" && (
          <div>
            <h1>Find out your speaking percentage!</h1>

            <h3>Instructions:</h3>

            <p>
              <u>You’ll need:</u>
            </p>
            <p>
              Two or more speakers <u>plus</u> a timekeeper (listener)
            </p>
            <p>
              <u>What to do:</u>
            </p>
            <p>
              1) Click “Get Started” to add the speaker’s names e.g. Adrian and
              Virginia
              <br />
              <br />
              2) Ready?!
              <br />
              <br />
              3) Choose who will begin speaking e.g. Adrian.
              <br />
              <br />
              4) When Adrian begins speaking, the timekeeper taps “ADRIAN
              SPEAKING”. As soon as Virginia says something, the timekeeper taps
              “VIRGINIA SPEAKING” and then the timekeeper continues to alternate
              between the speakers as they speak.
              <br />
              <br />
              5) At the end, click “Stop the Clock” to see the percentages and
              get a helpful hint.
            </p>
            <p>
              <u>Extra Practice:</u>
              <br />
              <br />
              Try using this app while watching TV (sitcoms, panel discussions
              and live debates etc.) or even on the bus. See what percentages
              the speakers have.
              <br />
              <br />
              Remember, there are different types of conversation, so your aim
              might not be even percentages so talk with your teacher about
              this!
            </p>
            <div>
              <Button
                color="primary"
                variant="raised"
                className={classes.submit}
                onClick={this.showConfig}
              >
                Get Started!
              </Button>
            </div>
          </div>
        )}
        {this.state.show === "config" && (
          <div>
            <Grid container spacing={8}>
              <Grid key="1" item xs={9}>
                <h2>&nbsp;</h2>
              </Grid>
              <Grid key="2" item xs={3}>
                <Button onClick={() => this.showHelp()}>Help</Button>
              </Grid>
            </Grid>

            <h3>People about to talk:</h3>
            {this.state.people.map(p => (
              <Grid key={p.name} container spacing={8}>
                <Grid item className={classes.personName}>
                  {p.name}
                </Grid>
                <Grid key="2" item>
                  <Button
                    variant="raised"
                    className={classes.button}
                    onClick={() => this.deletePerson(p)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            ))}
            <h3>Add new person:</h3>
            <Grid container spacing={8}>
              <Grid key="1" item>
                <div>
                  <TextField
                    name="personName"
                    value={this.state.newPerson.name}
                    onChange={this.handleNewPersonNameChange}
                  />
                </div>
                {showGenderSelector && genderSelector}
              </Grid>
              <Grid key="2" item>
                <Button
                  variant="raised"
                  className={classes.button}
                  onClick={this.savePerson}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <div>
              <Button
                color="primary"
                variant="raised"
                className={classes.submit}
                onClick={this.applyConfig}
              >
                Ready!
              </Button>
            </div>
          </div>
        )}
        {this.state.show === "timer" && (
          <div>
            <Grid container spacing={8}>
              <Grid key="1" item xs={9}>
                <h2>Total: {totalTime}</h2>
              </Grid>
              <Grid key="2" item xs={3}>
                <Button onClick={() => this.showConfig()}>Setup</Button>
                <Button onClick={() => this.showHelp()}>Help</Button>
              </Grid>
            </Grid>

            {this.state.people.map(p => (
              <div key={p.name + "_div"}>
                <Grid
                  container
                  spacing={8}
                  key={p.name + "_grid"}
                  className={
                    p.name === whoIsTalking ? classes.activeTalker : ""
                  }
                >
                  <Grid key={p.name + "0"} item xs={6}>
                    <Timer person={p} />
                  </Grid>
                  <Grid key={p.name + "1"} item xs={6}>
                    <div>
                      <Button
                        variant="raised"
                        className={classes.startButton}
                        onClick={() => this.updateWhoIsTalking(p)}
                      >
                        {p.name} Speaking
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                {!this.state.whoIsTalking &&
                  this.state.totalTime > 0 &&
                  !p.isTalkingEnough &&
                  p.varianceFromEqualTalking &&
                  p.varianceFromEqualTalking < 0 && (
                    <div>
                      <br />
                      Try to listen more! Practice asking more questions and
                      being aware of others trying to speak.
                      <br />
                      <hr />
                    </div>
                  )}
                {!this.state.whoIsTalking &&
                  this.state.totalTime > 0 &&
                  !p.isTalkingEnough &&
                  p.varianceFromEqualTalking &&
                  p.varianceFromEqualTalking > 0 && (
                    <div>
                      <br />
                      Try to speak more! Practice taking longer turns and
                      interupting politely.
                      <br />
                      <hr />
                    </div>
                  )}
                {!this.state.whoIsTalking &&
                  this.state.totalTime > 0 &&
                  p.isTalkingEnough && (
                    <div>
                      <br />
                      Great job! You are speaking and listening equally!
                      <br />
                      <hr />
                    </div>
                  )}
              </div>
            ))}

            <div>
              <Button
                color="primary"
                variant="raised"
                className={classes.stopTheClock}
                onClick={() => this.stopTimer()}
              >
                STOP THE CLOCK
              </Button>
              <Button
                variant="raised"
                className={classes.stopTheClock}
                onClick={() => this.resetTimer()}
              >
                RESET
              </Button>
            </div>
          </div>
        )}
        <div className={classes.proudly}>
          Proudly brought to you by &nbsp;
          <a href="https://99waysesl.com">99waysesl.com</a>
        </div>
      </Page>
    );
  }
}

export default withStyles(styles)(TimerApp);
