import { withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import PageTitle from './PageTitle';

const styles = {
  root: {
    minHeight: 'calc(100vh - 64px)',
    padding: 16,
    width: '90%',
  },
};

const Page: React.SFC<WithStyles<typeof styles>> = ({ classes, children }) => (
  <div>
    <PageTitle />
    <div className={classes.root}>{children}</div>
  </div>
);

export default withStyles(styles)(Page);
